import type { ReactElement } from 'react'
import { Link, useLocation } from 'react-router-dom'
import BottomNavigation from '@mui/material/BottomNavigation'
import BottomNavigationAction from '@mui/material/BottomNavigationAction'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import AppsIcon from '@mui/icons-material/Apps'

export default function BottomNav(): ReactElement {
    const location = useLocation()

    return (
        <BottomNavigation showLabels value={location.pathname} sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}>
            <BottomNavigationAction label='Upload' value='/upload' icon={<CloudUploadIcon />} component={Link} to='/upload' />
            <BottomNavigationAction label='All Images' value='/images' icon={<AppsIcon />} component={Link} to='/images' />
        </BottomNavigation>
    )
}
