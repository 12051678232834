import React from 'react'
import ReactDOM from 'react-dom/client'
import CssBaseline from '@mui/material/CssBaseline'
import { createHashRouter, RouterProvider, defer } from 'react-router-dom'

import App from './App'
import { allImages } from './api'

const router = createHashRouter([
    {
        path: '/',
        element: <App />,
        children: [
            {
                path: 'upload',
                lazy: () => import('./pages/Upload'),
            },
            {
                path: 'images',
                lazy: () => import('./pages/AllImages'),
                loader: async () => {
                    return defer({ images: allImages() })
                }
            }
        ]
    }
])

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
)
root.render(
    <React.StrictMode>
        <CssBaseline />
        <RouterProvider router={router} />
    </React.StrictMode>
)
