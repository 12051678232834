import { ApiUploadResponse, ApiAllImagesResponse } from './types'
import BaseError from '../utils/baseError'

export class ApiError extends BaseError {
    public status: number

    constructor(error: string, status: number) {
        super(`Server returned ${status}: ${error}`)
        this.status = status
    }
}

export async function upload(file: File): Promise<string> {
    const formData = new FormData()
    formData.append('file', file)
    const res = await fetch('https://backend.image.nickchen120235.dev/upload', {
        method: 'POST',
        body: formData
    })
    if (!res.ok) throw new ApiError(res.statusText, res.status)

    const data = await res.json() as ApiUploadResponse
    if (data.result === 'success') return data.url
    else {
        const { error } = data
        throw new ApiError(error, res.status)
    }
}

export async function allImages(): Promise<string[]> {
    const res = await fetch('https://backend.image.nickchen120235.dev/all')
    if (!res.ok) throw new ApiError(res.statusText, res.status)

    const data = await res.json() as ApiAllImagesResponse
    if (data.result === 'success') return data.images
    else {
        const { error } = data
        throw new ApiError(error, res.status)
    }
}

export async function deleteImage(id: string): Promise<void> {
    const res = await fetch(`https://backend.image.nickchen120235.dev/${id}`, {
        method: 'DELETE'
    })
    if (!res.ok) throw new ApiError(res.statusText, res.status)
}
