import type { ReactElement } from 'react'
import { Outlet } from 'react-router-dom'
import Container from '@mui/material/Container'
import { SnackbarProvider } from 'notistack'

import Header from './component/Header'
import BottomNav from './component/BottomNav'

export default function App(): ReactElement {
    return (
        <SnackbarProvider disableWindowBlurListener TransitionProps={{ direction: 'up' }}>
            <Header />
            <Container maxWidth='xl' sx={{ paddingTop: 1 }}>
                <Outlet />
            </Container>
            <BottomNav />
        </SnackbarProvider>
    )
}
