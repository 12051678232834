import type { ReactElement } from 'react'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import useScrollTrigger from '@mui/material/useScrollTrigger'
import Slide from '@mui/material/Slide'

function HideOnScroll({ children }: { children: ReactElement }): ReactElement {
    const trigger = useScrollTrigger()
    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    )
}

export default function Header(): ReactElement {
    return (<>
        <HideOnScroll>
            <AppBar>
                <Toolbar>
                    <Typography variant='h6'>nickchen120235&apos;s Image Host</Typography>
                </Toolbar>
            </AppBar>
        </HideOnScroll>
        <Toolbar />
    </>)
}
